import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p>©2023 shaneh.dev</p>
    </div>
  )
}

export default Footer